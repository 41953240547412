import { format } from "date-fns";
import { ru } from "date-fns/locale";

// export default function (d) {
//   const my = ["Янв", "Фев", "Мар", "Апр", "Май", "Июн", "Июл", "Авг", "Сен", "Окт", "Ноя", "Дек"];
//   const myDate = new Date(d);
//   return myDate.getFullYear() + "." + my[myDate.getMonth()];
// }
export default function (d) {
  return format(Date.parse(d), "yyyy.LLL", { locale: ru });
}
