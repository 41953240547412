import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";

import 'normalize.css';
import "./assets/css/reset.module.css";
import "./assets/css/roboto.module.css";

import components from "@/components";

const app = createApp(App);

components.forEach((component) => {
  app.component(component.name, component);
});

app.use(store).use(router).mount("#app");
app.config.globalProperties.$axios = axios; // почему-то не работает в корневом приложении app.vue

// Before 
// Vue.prototype.$http = () => {}

// After 
// const app = Vue.createApp({})
// app.config.globalProperties.$http = () => {}