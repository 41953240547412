<template>
  <div class="modal" v-if="shown" @click.stop="hideModal">
    <div @click.stop class="modal__content">
      <slot></slot>
    </div>
  </div>
</template>

<script>
export default {
  name: "mui-modal",
  props: {
    shown: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    hideModal() {
      this.$emit("update:shown", false);
    },
  },
};
</script>

<style>
.modal {
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  /* right: 0;
  bottom: 0; */
  background: rgba(175, 175, 175, 0.2);
  position: fixed;
  /* display: flex; */
}
.modal__content {
  /* margin: auto; */
  /* position: absolute; */
  border: 1px solid var(--main-color);
  position: relative;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: white;
  border-radius: 5px;
  max-width: 400px;
  /* min-height: 200px; */
  padding: 20px;
}
</style>