<template>
  <div class="container__login">
    <h3 style="text-align: center">Авторизация</h3>
    <div class="login__errors">
      {{ errors }}
    </div>
    <form class="form" name="login">
      <label class="label__login" for="username">Имя пользователя</label>
      <mui-input type="text" name="username" id="username" />
      <label class="label__login" for="password">Пароль</label>
      <mui-input type="password" name="password" id="password" />
      <mui-button class="btn__login" @click.prevent="login">Вход</mui-button>
    </form>
  </div>
</template>

<script>
export default {
  name: "login-form",
  data() {
    return {
      errors: "",
    };
  },
  methods: {
    login() {
      this.$axios({
        method: "post",
        url: this.$store.state.dbpath,
        data: new FormData(document.forms.login),
      })
        .then((responce) => {
          // console.log(responce.data);
          if (responce.data.auth) {
            localStorage.setItem("token", responce.data.token);
            this.$emit("authSuccess", responce.data);
          } else {
            this.errors = responce.data.error;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style>
.form > input {
  width: 100%;
}
.container__login {
  padding: 10px;
}
.login__errors {
  color: red;
  margin-bottom: 10px;
}
.label__login {
  display: inline-block;
  margin: 10px 0 3px;
}
.btn__login {
  display: block;
  margin: 20px auto 0;
}
.btn__login:focus {
  outline: none;
  border: 1px solid #2196f3;
  color: #2196f3;
}
</style>
