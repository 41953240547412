<template>
  <div class="modal" v-show="loading">
    <mui-loader class="lds-modal" :loading="loading" :color="$store.state.loaderColor"></mui-loader>
  </div>
  <div v-show="!loading && authData.auth" class="main container">
    <div class="filter__wrap">
      <div class="filter__wrap--item">
        <label class="filter__label">Прораб</label>
        <mui-select class="select__prorab" v-model="cProrab" :options="prorabs"></mui-select>
      </div>
      <div class="filter__wrap--item">
        <label class="filter__label">Объект</label>
        <mui-select class="select__krobjects" v-model="cKrobjects" :options="krobjectsFiltered"></mui-select>
      </div>
      <div class="filter__wrap--item">
        <input class="visually-hidden" type="checkbox" id="flMyObjects" v-model="flMyObjects" />
        <label
          id="flMyObjectsLabel"
          class="filter__label__my-objetcs"
          :class="{ 'filter__label__my-objetcs-checked': flMyObjects }"
          for="flMyObjects"
          >Только мои объекты</label
        >
      </div>
      <div class="filter__wrap--item">
        <label class="filter__label">Вид работ</label>
        <mui-select class="select__vidRabot" v-model="cVidRabot" :options="vidRabotsFiltered"></mui-select>
      </div>
    </div>
    <div class="filter__wrap__bottom">
      <div class="filter__wrap--item">
        <label class="filter__label" for="filterInput">Поиск:</label>
        <mui-input class="filter__input" v-model="filterInput" type="text" id="filterInput" />
      </div>
      <div class="filter__wrap--item" ref="updateButton">
        <mui-button @click="loadObjem">Загрузить</mui-button>
      </div>
    </div>
    <div v-if="objemRabot.length" class="table__wrap">
      <table class="table__main">
        <tr class="table__header-row">
          <th v-for="h in tableStructureVisible" :key="h">
            {{ h.name }}
            <button
              class="btn-arrowed table__header-btn"
              :class="{ 'table__details-row-shown': tableHideAllBtnStatus }"
              v-if="h.field == 'naimrabot'"
              title="Свернуть все детали"
              @click="hideAllDetails"
            >
              <mui-switch-arrow></mui-switch-arrow>
            </button>
          </th>
        </tr>
        <template style="white-space: nowrap" v-for="ob in krObjectsObjemRabot" :key="ob.id">
          <tr v-show="!cKrobjects" class="table__object-row">
            <td>
              {{ ob.naim.toUpperCase() }}
            </td>
            <td></td>
            <td></td>
          </tr>
          <template v-for="vid in VidRabotFilteredForObject(ob.id)" :key="vid.id">
            <tr v-show="!cVidRabot" class="table__object-row">
              <td>
                {{ vid.naim }}
              </td>
              <td></td>
              <td></td>
            </tr>
            <template v-for="row in objemRabotFilteredForObjectWithVidRabot(ob.id, vid.id)" :key="row.id">
              <tr class="table__objem-row">
                <td
                  v-for="(td, i) in tableStructureVisible"
                  :key="i"
                  :class="{
                    'table__objem-row-edizm': td.field == 'edizm',
                    'table__objem-row-objem': td.field == 'objem',
                  }"
                >
                  {{ row[td.field] }}
                  <button
                    v-if="td.field == 'naimrabot'"
                    class="btn-arrowed table__details-row-btn"
                    :class="{ 'table__details-row-shown': tableRowsActive.includes(row.id) }"
                    title="Показать детально по бригадам и месяцам"
                    @click="showDetails(row.id, $event)"
                  >
                    <mui-switch-arrow></mui-switch-arrow>
                  </button>
                </td>
              </tr>
              <tr :class="{ hidden: !tableRowsActive.includes(row.id) }" ref="rowdetails" class="table__details-row">
                <td v-html="tableRowsDetails[row.id]"></td>
              </tr>
            </template>
          </template>
        </template>
      </table>
    </div>
    <div v-else class="table__no-data">Данных нет</div>
  </div>
</template>

<script>
import "@/helpers/myArrayPrototype.js";
import yearMonth from "@/helpers/yearMonth.js";

export default {
  emits: ["logoff"], // если передается emit через модуль (в данном случае из app.vue=>router-view в котором есть Main) то нужно объявлять emit тут
  data() {
    return {
      loading: false,
      cProrab: "",
      cKrobjects: "",
      cVidRabot: "",
      filterInput: "",
      prorabs: [],
      krobjects: [],
      vidRabots: [],
      objemRabot: [],
      flMyObjects: true,
      tableRowsActive: [],
      tableRowsDetails: [],
      tableHideAllBtnStatus: false,
      tableStructure: [
        {
          field: "idkrobject",
          name: "idkrobject",
          visible: false,
        },
        {
          field: "naimrabot",
          name: "Наименование работ",
          visible: true,
        },
        {
          field: "edizm",
          name: "Ед изм",
          visible: true,
        },
        {
          field: "objem",
          name: "Объем",
          visible: true,
        },
      ],
    };
  },
  props: {
    authData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    loadData() {
      if (this.authData.token) {
        this.loading = true;
        let postFormData = new FormData();
        postFormData.append("token", this.authData.token);
        this.$axios({
          method: "post",
          url: this.$store.state.dbpath + "/api/users/getProrabs",
          data: postFormData,
        })
          .then((responce) => {
            if (responce.data.success) {
              this.prorabs = responce.data.data;
            } else {
              this.$emit("logoff");
              console.log(responce.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(() => {
            const checkUserInProrabs = this.prorabs.filter((p) => {
              return p.id == this.authData.userId;
            }); // проверяем есть ли пользователь в списке прорабов
            if (checkUserInProrabs.length) {
              this.cProrab = "" + this.authData.userId;
            } // выбираем его в селекторе при загрузке страницы
            document.querySelector(".select__prorab").value = this.cProrab;
          });

        // загрузка объектов
        this.$axios({
          method: "post",
          url: this.$store.state.dbpath + "/api/krobjects/get",
          data: postFormData,
        })
          .then((responce) => {
            if (responce.data.success) {
              this.krobjects = JSON.parse(responce.data.data);
              this.krobjects.sort((e1, e2) => {
                return e1.naim > e2.naim ? 1 : -1;
              });
            } else {
              console.log(responce.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
          });

        // загрузка видов работ
        this.$axios({
          method: "post",
          url: this.$store.state.dbpath + "/api/vidrabot/get",
          data: postFormData,
        })
          .then((responce) => {
            if (responce.data.success) {
              this.vidRabots = JSON.parse(responce.data.data);
              this.vidRabots.sort((e1, e2) => {
                return e1.naim > e2.naim ? 1 : -1;
              });
            } else {
              console.log(responce.data.error);
            }
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(() => {
            this.loading = false;
          });
      }
    },
    loadObjem() {
      // загрузка объемов работ
      this.loading = true;
      this.tableRowsActive = [];
      this.tableRowsDetails = [];
      let postFormData = new FormData();
      postFormData.append("token", this.authData.token);
      postFormData.append("groupfields", "idkrobject, naimrabot, edizm, iduser, idvidrabot");
      postFormData.append("whereStatement", this.getWhereStr);
      this.$axios({
        method: "post",
        url: this.$store.state.dbpath + "/api/objemrabot/getdata",
        data: postFormData,
      })
        .then((responce) => {
          if (responce.data.success) {
            this.objemRabot = JSON.parse(responce.data.data);
            this.objemRabot.addIndex();
            // this.objemRabot.forEach((item, index) => {
            //   item.id = index;
            // });
            this.$refs.updateButton.querySelector(".btn").disabled = true;
          } else {
            this.$emit("logoff");
            console.log(responce.data.error);
          }
        })
        .catch(function (error) {
          console.log(error);
        })
        .finally(() => {
          this.loading = false;
          // отпускаю кнопку после изменения getWhereStr
        });
    },
    VidRabotFilteredForObject(objId) {
      const spisok = [
        ...new Set(this.objemRabotFiltered.filter((e) => e.idkrobject == objId).map((a) => a.idvidrabot)),
      ];
      return this.vidRabots.filter((i) => spisok.includes(i.id));
    },
    objemRabotFilteredForObject(objId) {
      return this.objemRabotFiltered.filter((e) => e.idkrobject == objId);
    },
    objemRabotFilteredForObjectWithVidRabot(objId, vidId) {
      return this.objemRabotFiltered.filter((e) => e.idkrobject == objId && e.idvidrabot == vidId);
    },
    showDetails(rowId, event) {
      // загрузка детали по записи
      // this.loading = true;
      // const detailsRow = this.$refs.rowdetails.find(
      //   (el) => el.attributes["data-key"].nodeValue == rowId
      // );

      // проверяем развернута ли область с деталями
      const index = this.tableRowsActive.findIndex((t) => t == rowId);
      if (index >= 0) {
        this.tableRowsActive.splice(index, 1);
      } else {
        this.tableRowsActive.push(rowId);

        // провереяем загружали ли уже данные
        if (this.tableRowsDetails[rowId] === undefined) {
          // если нет то загружаем данные с сайта
          let postFormData = new FormData();
          postFormData.append("token", this.authData.token);
          postFormData.append("idkrobject", this.objemRabot[rowId].idkrobject);
          postFormData.append("idvidrabot", this.objemRabot[rowId].idvidrabot);
          postFormData.append("naimrabot", this.objemRabot[rowId].naimrabot);
          if (this.objemRabot[rowId].edizm) postFormData.append("edizm", this.objemRabot[rowId].edizm);
          this.$axios({
            method: "post",
            url: this.$store.state.dbpath + "/api/objemrabot/get",
            data: postFormData,
          })
            .then((responce) => {
              if (responce.data.success) {
                const result = JSON.parse(responce.data.data).sort((a, b) => a.monthYear.localeCompare(b.monthYear));
                // console.log(result);
                const detailsText = result
                  .map((e) => {
                    const brig = e.brigadir || "Не указан";
                    const myDate = yearMonth(e.monthYear);
                    return `${brig} (${myDate}) - ${e.objem} </br>`;
                  })
                  .join("");
                this.tableRowsDetails[rowId] = `${detailsText}`;
              } else {
                console.log(responce.data.error);
              }
            })
            .catch(function (error) {
              console.log(error);
            })
            .finally(() => {
              // this.loading = false;
            });
        }
      }
    },
    hideAllDetails(e) {
      // удаляем window и document из массива, они не работают с contains
      // const btn = e.path.slice(0, -2).find((el) => el.classList.contains("btn-arrowed"));
      // btn.classList.add("table__details-row-shown");
      this.tableHideAllBtnStatus = true;
      setTimeout(() => {
        this.tableRowsActive = [];
        // btn.classList.remove("table__details-row-shown");
        this.tableHideAllBtnStatus = false;
      }, 250);
    },
  },
  mounted() {
    this.loadData();
  },
  computed: {
    krobjectsFiltered() {
      let krobjectsWithFilter = this.flMyObjects
        ? this.krobjects.filter((e) => e.iduser === this.cProrab)
        : this.krobjects;
      return [{ id: "", iduser: "", naim: "---" }, ...krobjectsWithFilter];
    },
    vidRabotsFiltered() {
      // let krobjectsWithFilter = this.flMyObjects
      //   ? this.krobjects.filter((e) => e.iduser === this.cProrab)
      //   : this.krobjects;
      return [{ id: "", naim: "---" }, ...this.vidRabots];
    },
    getWhereStr() {
      let whereStr = "";
      whereStr += this.cKrobjects ? "idkrobject=" + this.cKrobjects + " AND " : "";
      whereStr += this.cProrab && this.flMyObjects ? "iduser=" + this.cProrab + " AND " : "";
      // whereStr += this.cVidRabot ? "idvidrabot=" + this.cVidRabot + " AND " : "";
      // whereStr += (this.cKrobjects)? "idkrobject=" + this.cKrobjects : "";
      whereStr = whereStr ? whereStr.slice(0, -5) : "";
      return whereStr;
    },
    objemRabotFiltered() {
      return this.objemRabot.filter(
        (e) =>
          e.naimrabot.toLowerCase().includes(this.filterInput.toLowerCase()) &&
          (this.cKrobjects ? e.idkrobject == this.cKrobjects : true) &&
          (this.cVidRabot ? e.idvidrabot == this.cVidRabot : true)
      );
    },
    tableStructureVisible() {
      return this.tableStructure.filter((e) => e.visible);
    },
    krObjectsObjemRabot() {
      // так получаются все уникальные ключи из массива: [...new Set(this.objemRabotFiltered.map(a => a.idkrobject))]
      const spisok = [...new Set(this.objemRabotFiltered.map((a) => a.idkrobject))];
      return this.krobjects.filter((i) => spisok.includes(i.id));
    },
    ObjemRabotConditions() {
      return {
        id: [...new Set(this.objemRabotFiltered.map((a) => a.idkrobject))],
        iduser: [this.cProrab],
      };
    },
  },
  watch: {
    authData() {
      this.loadData();
    },
    krobjectsFiltered() {
      const checkObjInSelect = this.krobjectsFiltered.filter((p) => {
        return p.id == this.cKrobjects;
      }); // проверяем остался ли объект в select после обновления
      if (!checkObjInSelect.length) {
        this.cKrobjects = "";
      } // обнуляем если нет
    },
    getWhereStr() {
      this.$refs.updateButton.querySelector(".btn").disabled = false;
    },
  },
};
</script>

<style scoped>
.hidden {
  visibility: collapse;
  opacity: 0;
}
.filter__wrap::before {
  content: "";
  display: block;
  background-color: rgba(33, 33, 33, 0.3);
  height: 1px;
  width: 100%;
  margin-bottom: 10px;
}
.filter__wrap__bottom {
  margin: 5px auto;
}
.filter__wrap__bottom {
  max-width: 800px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: flex-end;
}

@media screen and (max-width: 419px) {
  #filterInput {
    width: 110px;
  }
}
@media screen and (min-width: 768px) {
  .filter__wrap {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
  }
  .filter__wrap--item {
    margin-left: 5px;
  }
}
.filter__label {
  margin-right: 5px;
}
.filter__label__my-objetcs {
  font-size: 0.8em;
  cursor: pointer;
  display: inline-block;
  padding: 2.5px 5px;
  border-radius: 4px;
  background-color: lightgrey;
  border: 1px solid darkgray;
  white-space: nowrap;
}
.filter__label__my-objetcs-checked {
  color: white;
  background-color: var(--main-color);
  border: 1px solid var(--main-color);
}
.filter__wrap--item {
  margin-bottom: 5px;
}
.select__krobjects {
  width: 220px;
}
.filter__input.input {
  padding: 6px 8px;
  max-width: 200px;
}
.filter__wrap--item {
  margin-bottom: 5px;
  text-align: center;
}
.filter__wrap--item button {
  padding: 6px 12px;
}
.filter__label__my-objetcs:focus,
.filter__wrap--item .select:focus,
.filter__wrap--item button:focus {
  outline: none;
  border: 1px solid #2196f3;
  /* color: #2196f3; */
}
.table__wrap {
  margin: auto;
  max-width: 800px;
  font-size: 14px;
}
.table__main {
  width: 100%;
  margin-bottom: 20px;
  border: 1px solid #dddddd;
  border-collapse: collapse;
}
.table__header-row th {
  font-weight: bold;
  padding: 5px;
  background: #f0ebeb;
  border: 1px solid #dddddd;
  position: relative;
}
.table__object-row td {
  font-weight: 700;
  border-right: none !important;
  border-left: none !important;
}
.table__objem-row td {
  position: relative;
}
.table__objem-row-edizm {
  text-align: center;
}
.table__objem-row-objem {
  text-align: end;
}
.btn-arrowed {
  cursor: pointer;
  border-radius: 50%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding: 0;
  transition: transform 200ms cubic-bezier(0.25, 0.8, 0.5, 1);
}
.table__details-row {
  transition: opacity 200ms cubic-bezier(0.25, 0.8, 0.5, 1);
}
.table__details-row-btn,
.table__header-btn {
  position: absolute;
  right: 5px;
  bottom: 5px;
}
.table__header-btn {
  background-color: white;
}
.table__details-row-shown {
  transform: rotate(-180deg);
}
.table__main td {
  border: 1px solid #dddddd;
  padding: 5px;
}
.table__no-data {
  font-size: 20px;
  text-align: center;
}
.table__details-row td {
  font-size: 0.9em;
  font-style: italic;
  padding: 2px 6px;
}
</style>
