<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" role="img" aria-hidden="true">
    <path d="M7.41,8.58L12,13.17L16.59,8.58L18,10L12,16L6,10L7.41,8.58Z"></path>
  </svg>
</template>

<script>
export default {
  name: "mui-switch-arrow",
};
</script>

<style scoped>
svg {
  fill: rgba(0, 0, 0, 0.5);
}
</style>
