<template>
  <mui-modal v-model:shown="loginFormShown">
    <login-form @authSuccess="authSuccess"></login-form>
  </mui-modal>
  <div class="nav__menu container">
    <img src="./assets/img/logo.png" alt="logo" width="100" />
    <div class="nav__user" v-show="!loading">
      <div v-if="authData.auth">
        <span>{{ authData.userName }}</span>
        <a class="nav__user__exit" @click.prevent="logoff">(выйти)</a>
      </div>
      <mui-button v-else @click="loginFormShown=true"> Авторизоваться </mui-button>
    </div>
    <div class="nav__links">
      <router-link class="nav__link" to="/">Home</router-link>
    </div>
  </div>
  <router-view @logoff="logoff" :authData="authData" />
</template>

<script>
import axios from "axios";
export default {
  data() {
    return {
      loading: true,
      authData: {
        auth: false, // обернуться все переменные по логину в массив, чтобы было проще передавать
        userId: 0,
        userName: "",
        token: "",
      },
      loginFormShown: false,
    };
  },
  methods: {
    checkLogin() {
      this.authData.token = localStorage.getItem("token");
      if (this.authData.token) {
        var postFormData = new FormData();
        postFormData.append("token", this.authData.token);
        axios({
          method: "post",
          url: this.$store.state.dbpath+"/api/users/getUserName",
          data: postFormData,
        })
          .then((response) => {
            this.authData.auth = response.data.auth;
            if (this.authData.auth) {
              this.authData.userName = response.data.userName;
              this.authData.userId = response.data.userId;
            } else {
              this.logoff();
            }
          })
          .catch(function (error) {
            console.log(error);
          })
          .finally(() => {
            // this.loading = false;
          });
      } else {
        // this.loading = false;
      }
      this.loading = false;
    },
    authSuccess(responce) {
      this.loginFormShown = false;
      this.authData = responce;
    },
    logoff() {
      this.authData = {
        auth: false,
        token: "",
        userId: 0,
        userName: "",
      };
      localStorage.removeItem("token");
    },
  },
  mounted() {
    this.checkLogin();
  },
};
</script>

<style>
:root {
  --main-color: teal;
}
* {
  box-sizing: border-box;
}
.visually-hidden {
  position: absolute;
  width: 1px;
  height: 1px;
  margin: -1px;
  border: 0;
  padding: 0;
  white-space: nowrap;
  clip-path: inset(100%);
  clip: rect(0 0 0 0);
  overflow: hidden;
}

body {
  font-family: Roboto, sans-serif;
  color: #2c3e50;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.71;
  letter-spacing: 0.03em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  padding: 0 15px;
  max-width: 1200px;
  margin: auto;
}

.nav__menu {
  display: flex;
  padding: 10px 30px;
  align-items: baseline;
  justify-content: left;
}
.nav__user {
  margin-left: auto;
  font-weight: 600;
}
.nav__links {
  display: flex;
  margin-left: auto;
}
.nav__link {
  color: #6e757c;
}
.nav__link.router-link-exact-active {
  /* color: #2196f3; */
  font-weight: 700;
  color: var(--main-color);
}
.nav__link:not(:last-child)::after {
  content: "";
  display: inline-block;
  width: 17px;
  height: 1px;
  background-color: rgba(33, 33, 33, 0.3);
  transform: rotate(90deg);
  vertical-align: middle;
}
.nav__user__exit {
  margin-left: 4px;
  color: var(--main-color);
  /* color: #2196f3; */
  font-size: 0.9em;
}
.nav__user__exit:hover {
  text-decoration: underline;
  cursor: pointer;
}
</style>
