import { createStore } from 'vuex'

export default createStore({
  state: {
    // dbpath: "http://localhost:3000",
    // dbpath: "http://db.refresh",
    dbpath: "https://db.refresh.by",
    loaderColor: "var(--main-color)",
  },
  mutations: {
  },
  actions: {
  },
  modules: {
  }
})
