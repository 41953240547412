Array.prototype.chooseFields = function (fieldsList = "") {
  console.log(this);
    newObj = JSON.parse(JSON.stringify(this));
    newObj.forEach((e) =>
      Object.keys(e).forEach((k) => {
        if (!fieldsList.includes(k)) {
          delete e[k];
        }
      })
    );
    return newObj;
};

Array.prototype.addIndex = function (idName = "id") {
  return this.map((item, index) => {
    item[idName] = index;
  });
};
