<template>
  <button class="btn">
    <slot></slot>
  </button>
</template>

<script>
export default {
  name: "mui-button",
};
</script>

<style>

.btn {
  cursor: pointer;
  padding: 10px 15px;
  /* margin: 5px; */
  /* background: none; */
  color: var(--main-color);
  border: 1px solid var(--main-color);
  border-radius: 4px;
}
.btn-red {
  background-color: tomato;
}
.btn-success {
  border: 1px solid transparent;
  color: #fff;
  background-color: #2196f3;
}
</style>