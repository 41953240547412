<template>
  <select class="select" @change="changeOptions">
    <option v-for="option in options" :key="option.id" :value="option.id">
      {{ option.naim }}
    </option>
  </select>
</template>

<script>
export default {
  name: "mui-select",
  props: {
    modelValue: String,
    options: {
      type: Array,
      default: () => [], // так желательно объявлять пустой массив
    },
  },
  methods: {
    changeOptions(event) {
      this.$emit("update:modelValue", event.target.value)
    },
  },
}
</script>

<style>
.select {
  outline: none;
  padding: 3px 5px;
  border: 1px solid var(--main-color);
  border-radius: 4px;
}
</style>