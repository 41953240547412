<template>
  <input v-model="modelValue" @input="inputEvent" class="input" />
</template>

<script>
export default {
  name: 'mui-input',
  props: {
    modelValue: [String, Number],
  },
  methods: {
    inputEvent(event) {
      this.$emit("update:modelValue", event.target.value);
    },
  },
};
</script>

<style>
.input {
  padding: 10px 15px;
  border:1px solid rgba(33,33,33,0.2);
  border-radius: 3px;
}
input:focus {
  outline: none;
  border: 1px solid #2196f3;
}
</style>