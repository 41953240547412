import MuiButton from "@/components/UI/MuiButton";
import MuiInput from "@/components/UI/MuiInput";
import MuiModal from "@/components/UI/MuiModal";
import MuiSelect from "@/components/UI/MuiSelect";
import MuiLoader from "@/components/UI/MuiLoader";
import MuiSwitchArrow from "@/components/UI/MuiSwitchArrow";

import LoginForm from "@/components/LoginForm";

export default [MuiButton, MuiInput, MuiModal, MuiSelect, MuiLoader, MuiSwitchArrow, LoginForm];
